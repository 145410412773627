var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"license-container"},[_vm._m(0),_c('div',{staticClass:"table-header"},[_c('div',[_c('span',{staticClass:"title"},[_vm._v("会员授权许可对比")]),_c('span',{staticClass:"desc"}),_c('span',{staticClass:"person"},[_vm._v("个人会员（非商用）"),_c('el-tooltip',{attrs:{"content":"被授权人仅限为自然人，不得将授权作品用于除被授权人以外的任何第三方机构或公司；被授权项目仅适用于非商业行为。","placement":"top"}},[_c('i',{staticClass:"el-icon-question"})])],1),_c('span',{staticClass:"company"},[_vm._v("企业会员")])])]),_c('div',{staticClass:"table-content"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_c('div',[_c('div',[_c('span',{staticClass:"title"},[_vm._v("其他项目：")]),_c('span',{staticClass:"special-content"},[_c('span',{staticClass:"c"},[_vm._v("如果您正在制作以上未列出的项目或有增加被授权企业数的需求，我们的团队将很乐意为您的需求进行量身定制")]),_c('span',{staticClass:"contract-wrap"},[_c('span',{staticClass:"contract",on:{"click":_vm.qimoChatClick}}),_c('span',{staticClass:"contract-border"})])])])])]),_c('div',{staticClass:"table-footer"},[_vm._v(" 注：订阅会员可下载全站素材，并在相应会员授权范围内使用，个人会员仅支持授权非商用项目，若您的项目属于商业用途请订阅企业会员，会员产品购买成功后不支持退换和转让，其中图片素材不可用于原创认证及线下渠道。 ")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"license-title"},[_c('h1',[_vm._v("授权许可对比")]),_c('h4',[_vm._v("MEMBERSHIP AUTHORIZATION CONTRAST")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('span',{staticClass:"title"},[_vm._v("授权期限：")]),_c('span',{staticClass:"desc"},[_vm._v("* 电商店铺用途、直播、公播授权期限与订阅服务期限一致")]),_c('span',{staticClass:"person"},[_vm._v("随片永久")]),_c('span',{staticClass:"company"},[_vm._v("随片永久")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('span',{staticClass:"title"},[_vm._v("投放地域：")]),_c('span',{staticClass:"desc"}),_c('span',{staticClass:"person"},[_vm._v("中国")]),_c('span',{staticClass:"company"},[_vm._v("全球")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('span',{staticClass:"title"},[_vm._v("商业广告/宣传片：")]),_c('span',{staticClass:"desc"},[_vm._v("发布于各类互联网上、移动端、电视、电商平台和线下的广告等（图片素材不能用于电视及线下广告）")]),_c('span',{staticClass:"person"},[_c('img',{attrs:{"src":require("@/assets/svg/license_not_allow.svg"),"alt":""}})]),_c('span',{staticClass:"company"},[_c('img',{attrs:{"src":require("@/assets/svg/license_allow.svg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('span',{staticClass:"title"},[_vm._v("自媒体平台：")]),_c('span',{staticClass:"desc"},[_vm._v("国内自媒体平台：微信公众号、视频号、微博、哔哩哔哩、斗鱼、抖音、快手等")]),_c('span',{staticClass:"person"},[_c('img',{attrs:{"src":require("@/assets/svg/license_allow.svg"),"alt":""}})]),_c('span',{staticClass:"company"},[_c('img',{attrs:{"src":require("@/assets/svg/license_allow.svg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('span',{staticClass:"title"}),_c('span',{staticClass:"desc"},[_vm._v("海外自媒体平台：YouTube、Facebook、TikTok、Twitter、Instagram等")]),_c('span',{staticClass:"person"},[_c('img',{attrs:{"src":require("@/assets/svg/license_not_allow.svg"),"alt":""}})]),_c('span',{staticClass:"company"},[_c('img',{attrs:{"src":require("@/assets/svg/license_allow.svg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('span',{staticClass:"title"},[_vm._v("直播/电台FM：")]),_c('span',{staticClass:"desc"},[_vm._v("广播剧、有声书、电台FM、网络节目、网络直播节目等")]),_c('span',{staticClass:"person"},[_c('img',{attrs:{"src":require("@/assets/svg/license_allow.svg"),"alt":""}})]),_c('span',{staticClass:"company"},[_c('img',{attrs:{"src":require("@/assets/svg/license_allow.svg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('span',{staticClass:"title"},[_vm._v("产品内置：")]),_c('span',{staticClass:"desc"},[_vm._v("个人制作小程序、H5、小游戏等（图片素材仅能用于微信小程序配图）")]),_c('span',{staticClass:"person"},[_c('img',{attrs:{"src":require("@/assets/svg/license_allow.svg"),"alt":""}})]),_c('span',{staticClass:"company"},[_c('img',{attrs:{"src":require("@/assets/svg/license_allow.svg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('span',{staticClass:"title"}),_c('span',{staticClass:"desc"},[_vm._v("企业制作小程序、H5、小游戏；官网、网站装饰、APP、皮肤/手机主题等")]),_c('span',{staticClass:"person"},[_c('img',{attrs:{"src":require("@/assets/svg/license_not_allow.svg"),"alt":""}})]),_c('span',{staticClass:"company"},[_c('img',{attrs:{"src":require("@/assets/svg/license_allow.svg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('span',{staticClass:"title"},[_vm._v("生活用途：")]),_c('span',{staticClass:"desc"},[_vm._v("家庭视频：电子相册、婚礼视频、生活视频等纪念性音视频内容")]),_c('span',{staticClass:"person"},[_c('img',{attrs:{"src":require("@/assets/svg/license_allow.svg"),"alt":""}})]),_c('span',{staticClass:"company"},[_c('img',{attrs:{"src":require("@/assets/svg/license_allow.svg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('span',{staticClass:"title"}),_c('span',{staticClass:"desc"},[_vm._v("个人作品：个人航拍、旅拍、短剧等")]),_c('span',{staticClass:"person"},[_c('img',{attrs:{"src":require("@/assets/svg/license_allow.svg"),"alt":""}})]),_c('span',{staticClass:"company"},[_c('img',{attrs:{"src":require("@/assets/svg/license_allow.svg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('span',{staticClass:"title"},[_vm._v("办公用途：")]),_c('span',{staticClass:"desc"},[_vm._v("企业、事业、政府机关、团体、学校、医院等单位内部宣传或内部活动使用；VI设计、营销策划方案、商业提案、项目计划书、年度报告等配图（仅图片素材可以使用）")]),_c('span',{staticClass:"person"},[_c('img',{attrs:{"src":require("@/assets/svg/license_not_allow.svg"),"alt":""}})]),_c('span',{staticClass:"company"},[_c('img',{attrs:{"src":require("@/assets/svg/license_allow.svg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('span',{staticClass:"title"},[_vm._v("课程教育用途："),_c('br'),_vm._v("(图片素材不可使用)")]),_c('span',{staticClass:"desc"},[_vm._v("个人开设的网络直播课、毕业设计、个人参赛等课程")]),_c('span',{staticClass:"person"},[_c('img',{attrs:{"src":require("@/assets/svg/license_allow.svg"),"alt":""}})]),_c('span',{staticClass:"company"},[_c('img',{attrs:{"src":require("@/assets/svg/license_allow.svg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('span',{staticClass:"title"}),_c('span',{staticClass:"desc"},[_vm._v("培训机构、公开课程、网络直播课程、在线录播课程、教育平台等付费课程")]),_c('span',{staticClass:"person"},[_c('img',{attrs:{"src":require("@/assets/svg/license_not_allow.svg"),"alt":""}})]),_c('span',{staticClass:"company"},[_c('img',{attrs:{"src":require("@/assets/svg/license_allow.svg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('span',{staticClass:"title"},[_vm._v("电商店铺用途：")]),_c('span',{staticClass:"desc"},[_vm._v("淘宝、京东、天猫、拼多多等第三方电商平台背景视频音乐、装饰配图")]),_c('span',{staticClass:"person"},[_c('img',{attrs:{"src":require("@/assets/svg/license_not_allow.svg"),"alt":""}})]),_c('span',{staticClass:"company"},[_c('img',{attrs:{"src":require("@/assets/svg/license_allow.svg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('span',{staticClass:"title"},[_vm._v("线下场所公播：")]),_c('span',{staticClass:"desc"},[_vm._v("商超、餐饮、咖啡厅、影院、酒吧、年会、展会、发布会等线下场所作为背景播放（图片素材不可使用）")]),_c('span',{staticClass:"person"},[_c('img',{attrs:{"src":require("@/assets/svg/license_not_allow.svg"),"alt":""}})]),_c('span',{staticClass:"company"},[_c('img',{attrs:{"src":require("@/assets/svg/license_allow.svg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('span',{staticClass:"title"},[_vm._v("特殊授权:")]),_c('span',{staticClass:"desc"},[_vm._v("制作成电影、电视剧、综艺以及NFT进行使用")]),_c('span',{staticClass:"person"},[_c('img',{attrs:{"src":require("@/assets/svg/license_not_allow.svg"),"alt":""}})]),_c('span',{staticClass:"company"},[_c('img',{attrs:{"src":require("@/assets/svg/license_not_allow.svg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('span',{staticClass:"title"}),_c('span',{staticClass:"desc"},[_vm._v("进行转授权、原创认证；用于商标、logo或实体印刷")]),_c('span',{staticClass:"person"},[_c('img',{attrs:{"src":require("@/assets/svg/license_not_allow.svg"),"alt":""}})]),_c('span',{staticClass:"company"},[_c('img',{attrs:{"src":require("@/assets/svg/license_not_allow.svg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('span',{staticClass:"title"}),_c('span',{staticClass:"desc"},[_vm._v("肖像权敏感授权：医疗、药物、保健品、养生、美容、整形、交友等行业")]),_c('span',{staticClass:"person"},[_c('img',{attrs:{"src":require("@/assets/svg/license_not_allow.svg"),"alt":""}})]),_c('span',{staticClass:"company"},[_c('img',{attrs:{"src":require("@/assets/svg/license_not_allow.svg"),"alt":""}})])])])
}]

export { render, staticRenderFns }