<template>
  <div class="package-container">
    <!-- banner -->
    <div class="banner-wrap">
      <img src="@/assets/image/new_set_bj.jpg" alt="">
      <div class="crumb-wapper">
        <vf-breadcrumb :data="breadData" type="banner"></vf-breadcrumb>
      </div>
      <div class="banner-container">
        <div class="title-wrap">
          <h1>{{packageType == 1 ? '个人订阅计划' : '企业订阅计划'}}</h1>
          <h2>-选择最适合您需求的会员订阅计划-</h2>
        </div>
        <div class="PurchaseTips" v-if="memberInfo && memberInfo.length > 0">
          <span class="PurchaseTipsICON"></span>
          <div class="banner">
            <swiper :options="swiperOption" ref="bannerSwiper" v-if="memberInfo.length > 0">
              <swiper-slide v-for="(item, index) in memberInfo" :key="`swiper_${index}`">
                <p>
                  用户【<span>{{ setStringlen(item.name) }}</span>】于{{ item.create_at }}购买了【{{
                    item.type == '1'
                    ? '个人' + item.good_name
                    : '企业' + item.good_name
                  }}】
                </p>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
    <div class="goods-container">
      <div class="tab-wrap">
        <div class="goods-tab" :class="{ tabCompany: packageType == 1 }">
          <div class="person-tab" :class="{ 'person-active': packageType == 1 }" @mouseenter="choosePackage(1)">
            <strong>个人会员<span>适用于个人非商用项目</span></strong>
            <img v-if="packageType == 2" src="../../assets/image/set_tab_arrow.svg" alt="">
          </div>
          <div class="company-tab" :class="{ 'company-active': packageType == 2 }" @mouseenter="choosePackage(2)">
            <strong>企业会员 <p>商用首选</p><span>适用于企业项目</span></strong>
            <img v-if="packageType == 1" src="../../assets/image/set_tab_arrow.svg" alt="">
          </div>
        </div>
      </div>
      <!-- @mouseenter="onMouse(i)"
          @mouseleave="onMouseLeve(i)" -->
      <div class="goods-wrap">
        <div
          v-for="(item,index) in packageList"
          :key="item.goods_id"
          @mouseenter="handleCheckGoods(index)"
          @mouseleave="handleCheckGoods('')"
          class="goods-item"
        >
          <div :class="[packageType == 1 ? 'person-goods-item' : 'company-goods-item', chooseIndex == index ? (item.is_month == 1 || item.is_sum == 3) ? 'month-choose' : 'choose' : '', (item.is_month==1 || item.is_sum == 3) ? 'month-good' : '',]">
            <div class="special-mask" v-if="item.is_month==1">
              <span class="lisheng">立省</span>
              <span class="money">￥{{item.cut_amount}}</span>
            </div>
            <div class="double-t" v-if="item.use_mode == 2 && item.type == 1">
              <span>年中特惠</span>
            </div>
            <div class="title">{{ item.name }}</div>
            <div class="content">
              <!-- is_sum 企业会员包月年付商品 -->
              <div class="price" v-if="item.is_sum == 3"><i>￥</i>{{ Number(item.per_price) }}</div>
              <div class="price" v-else><i>￥</i>{{ Number(item.price) }}</div>
              <div class="desc">
                <div class="origin-price" v-if="item.use_mode == 2 && item.type == 1">原价￥189</div>
                <div>
                  <span>下载次数</span>
                  <span class="num">{{ item.down_count == 'n' ? '不限' : item.down_count + '次' }}</span>
                </div>
                <div>
                  <span>单日下载限制</span>
                  <span class="num">{{ item.down_num }}次</span>
                </div>
                <div v-if="item.type == 2">
                  <span>可授权企业数</span>
                  <span class="num">{{ item.cert_num }}家</span>
                </div>
              </div>
              <div class="pay">
                <p v-if="item.is_month==1 || item.is_sum == 3" id="lijikaitong" data-agl-cvt="7" @click="handleMonthPay(item)">立即订阅</p>
                <p v-else id="lijikaitong" data-agl-cvt="7" @click="immediatePayment( item, true)">
                  {{ order_id != undefined ? '立即升级' : '立即订阅' }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fix-content" @click="handleScroll">
      <span>会员订阅许可对比</span>
      <div class="icons"><i class="el-icon-arrow-down"></i><i class="el-icon-arrow-down"></i></div>
    </div>
    <!-- 授权许可对比 -->
    <div ref="licenseForm">
      <LicenseCompare></LicenseCompare>
    </div>
    <!-- 常见问题 -->
    <CommonProblem></CommonProblem>
    <!-- 优惠支付 -->
    <LadderToPay ref="ladderPayRef" @ladderPay="topay"></LadderToPay>

    <!-- 选择支付方式 默认选中在线支付 -->
    <el-dialog class="payDiage" :visible="dialogVisiblemember" :destroy-on-close="false" :show-close="true"
      :append-to-body="false" :before-close="handleBeforeClose" width="640px">
      <!-- dialog_close.svg -->
      <div class="pay-continer">
        <div class="pay-type">
          <div :class="{ payActive: payType == 1 }" @click="handleChangePayInfo(1)">
            <img v-if="payType == 1" src="~@/assets/svg/pay/online_select.svg" alt="">
            <img v-else src="~@/assets/svg/pay/online.svg" alt="">
            <span>在线支付</span>
          </div>
          <div :class="{ payActive: payType == 2 }" @click="handleChangePayInfo(2)">
            <img v-if="payType == 2" src="~@/assets/svg/pay/public_select.svg" alt="">
            <img v-else src="~@/assets/svg/pay/public.svg" alt="">
            <span>对公转账</span>
          </div>
        </div>
        <div class="pay-splice-line"></div>
      <!-- 二维码区域 在线支付-->
      <div v-if="payType == 1" class="qr-code-container">
        <div class="pay-amount">实付金额：<span><i>￥</i>{{payGoodInfo.price}}</span></div>
        <div class="special-pay-tip" v-if="payGoodInfo.is_sum == 3">（年付:{{payGoodInfo.per_price}}元×12个月）</div>
        <div class="qr-code-wrap">
          <div v-if="wxPayQrCode && wxPayQrCode !== ''" class="qr-code">
            <div class="scande_border">
              <vue-qrcode :value="wxPayQrCode" :width="130" :height="130" margin="auto" style="margin: 10px;" />
            </div>
            <p style="margin-top: 22px">
              <img src="@/assets/image/wxpic.png" alt="" style="margin: 2px 7px 0 0" />
              <span style="font-weight: 400;color: rgba(0, 0, 0, 0.85);font-size: 12px;">微信支付</span>
            </p>
          </div>
          <div v-if="aliPayQrCode && aliPayQrCode !== ''" class="qr-code">
            <div class="scande_border">
              <iframe id="bdIframe" :src="aliPayQrCode" frameborder="0" width="130" height="130" style="margin: 10px"
                transform="scale(.5, .5)" scrolling="no" />
            </div>
            <p style="margin-top: 22px">
              <img src="@/assets/image/alipaypic.png" alt="" style="margin: 2px 7px 0 0" />
              <span style="font-weight: 400;color: rgba(0, 0, 0, 0.85);font-size: 12px;">支付宝支付</span>
            </p>
          </div>
        </div>
        <div class="agree">
          <span>支付即视为您同意</span><br>
          <a class="link-type" :href="userProtocol" target="_blank">《PlayList会员用户协议》</a>
        </div>
        <div class="qr-tip">* 支付宝签约支付成功后在 <span @click="goMine">个人中心-我的会员</span> 中查看已购会员</div>
      </div>
      <!-- 对公转账 -->
      <div v-if="payType == 2" class="public-container">
        <div class="pay-amount">实付金额：<span><i>￥</i>{{payGoodInfo.price}}</span></div>
        <div class="special-pay-tip" v-if="payGoodInfo.is_sum == 3">（年付:{{payGoodInfo.per_price}}元×12个月）</div>
        <el-descriptions :column="1">
          <el-descriptions-item label="账户名称">北京数音未来科技有限公司</el-descriptions-item>
          <el-descriptions-item label="公司账号">110060635018800064856</el-descriptions-item>
          <el-descriptions-item label="开户行">交通银行股份有限公司北京三元支行</el-descriptions-item>
          <!--<el-descriptions-item label="法定代表人">卢汉铁</el-descriptions-item>-->
          <el-descriptions-item label="联系电话">13522352793</el-descriptions-item>
        </el-descriptions>

        <el-form ref="publicForm" :model="publicForm" :rules="publicRules">
          <el-form-item label="付款回执单" prop="receipt">
            <SingleImageUpload v-model="publicForm.receipt" accept="image/*" :isclear="isclear" />
          </el-form-item>
          <el-form-item label="付款备注" prop="remark">
            <el-input v-model="publicForm.remark" type="textarea" placeholder="请输入付款备注"/>
          </el-form-item>
        </el-form>

        <p class="tip">
          完成线下转账/汇款后，请 <span onclick="qimoChatClick();">联系客服</span> 我们将及时为您开通会员权限
        </p>
        <div class="submit-btn-container">
          <!-- style="width: 96px; height: 32px; line-height: 32px;" -->
          <el-button type="primary" @click="handleSubmitPublicInfo">提交</el-button>
        </div>
      </div>
      </div>
    </el-dialog>

    <DialogForMonthPay ref="aliMonthPayRef" :alipayCode="aliMonthPayCode" @close="handleMontyPayClose()" @refresh="initMonthOrderSign(payGoodInfo)"></DialogForMonthPay>
        <!-- 致用户的一封信 -->
    <Letter :showLetter="showLetter" v-show="showLetter" @closeLetterDialog="closeLetter" />

    <div class="customVip" @click="showCustomVipDialog">定制会员请咨询客服：  135 2235 2793（同步微信号） ></div>
    <DialogForCustomVip ref="customRef"></DialogForCustomVip>

  </div>
</template>

<script>
import {
  fetchOrderPayByAliPay,
  initMonthOrderSign,
  fetchMonthOrderStatus,
  fetchOrderPayByPublicPay,
  fetchOrderPayByWxPay,
  fetchOrderPayStatusByPackage,
  fetchPackageList,
  submitPublicPayReceipt
} from '@/api/package'
import { getMemberbuyInfo } from '@/api/member'
import { mapGetters } from 'vuex'
import LicenseCompare from './components/LicenseCompare.vue'
import LadderToPay from './components/LadderToPay.vue'
import CommonProblem from './components/CommonProblem.vue'
import SingleImageUpload from './components/UploadPublicImage.vue'
import VueQrcode from '@/components/VueQrcode'
import DialogForMonthPay from './components/DialogForMonthPay.vue'
import DialogForCustomVip from './components/DialogForCustomVip.vue'
import Letter from '@/views/package/components/Letter'
import { payReloadTime, userProtocol } from '@/config'
import { formDateTime } from '@/utils/formDate.js'
import { send_eventApi } from '@/utils/common.js'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import VfBreadcrumb from '@/components/VfBreadcrumb.vue'

export default {
  name: 'Package',
  data() {
    return {
      breadData: [
        {breadcrumbName: 'PlayList', path: '/'},
        {breadcrumbName: '个人VIP'}
      ],
      userProtocol,
      order_id: null, // 升级套餐id
      packageType: 1, // 1:个人 2:企业
      chooseIndex: '',
      packages: {},
      payGoodInfo: {},


      payType: 1, // 支付方式
      wxPayQrCode: '', // 微信二维码
      aliPayQrCode: '', // 支付宝二维码
      aliMonthPayCode: '', // 支付宝连续包月二维码
      // 定时器
      wxPayTimer: null,
      aliPayOrderTimer: null,
      timerInfo: null,

      dialogVisiblemember: false,
      isclear: false, // 是否清空付款回执单
      showLetter: false, // 是否显示致用户的一封信
      publicForm: {
        receipt: '',
        remark: ''
      },
      publicRules: {
        receipt: [
          { required: true, message: '请上传付款回执单', trigger: 'change' }
        ],
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }]
      },
      memberInfo: [], // 会员信息
      swiperIndex: 0,
      swiperOption: {
        // 视频轮播配置项
        loop: true,
        direction: 'vertical', // 滑动方向 horizontal 水平 || vertical 垂直
        initialSlide: 0, // 默认设置从一张开始播
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        slidesPerView: 1, // 每页展示几条数据
        slidesPerGroup: 1, // 几条数据为一组
        on: {
          slideChange: function () {
            // 记录当前轮播的真实下标
            // self.$emit('updateSwiper', this.realIndex)
            this.swiperIndex = this.realIndex
          }
        }
      },
    }
  },
  components: {
    VfBreadcrumb,
    SingleImageUpload,
    LadderToPay,
    VueQrcode,
    LicenseCompare,
    CommonProblem,
    DialogForMonthPay,
    DialogForCustomVip,
    swiper,
    swiperSlide,
    Letter
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo'
    }),
    packageList() {
      return this.packages[this.packageType] ? this.packages[this.packageType].filter((i) => i.is_able == 1) : []
    },
    swiper() {
      return this.$refs.bannerSwiper.swiper
    }
  },
  watch:{
    '$route.query.type': {
      handler(v) {
        this.packageType = v
        this.breadData[1].breadcrumbName = v == 1 ? '个人VIP' : ' 企业VIP'
      }
    }
  },
  created() {
    this.order_id = this.$route.query.order_id
    this.packageType = Number(this.$route.query.type) || 1
    this.breadData[1].breadcrumbName = this.packageType == 1 ? '个人VIP' : ' 企业VIP'
    this.getPackageList()
    this.getMemberInfo()
  },
  // 离开当前页面记录离开前轮播的真实下标
  beforeRouteLeave(to, from, next) {
    console.log(from, '离开')
    this.handleClearCountDown('wxPayTimer')
    this.handleClearCountDown('aliPayOrderTimer')
    if (to.path !== '/package/page') {
      this.$store.commit('user/UPDATE_SWIPER_INDEX', this.swiperIndex)
    }
    next()
  },
  methods: {
    showCustomVipDialog(){
      this.$refs.customRef.handleOpen()
    },
    goMine(){
      this.$router.push({
        path: `/member/center/equity`
      })
    },
    handleScroll(){
      let targetBox = this.$refs.licenseForm
      let target = targetBox.offsetTop - 60
      window.scroll({ top: target, left: 0, behavior: 'smooth' })
    },
    handleCheckGoods(index) {
      this.chooseIndex = index
    },
    choosePackage(val){
      // this.getPackageList()
      this.packageType = !this.order_id ? val : this.packageType
      this.breadData[1].breadcrumbName = this.packageType == 1 ? '个人VIP' : ' 企业VIP'
    },

    /**
     * 获取套餐列表
     * 2021-12-22 18:54:53
     * @author SnowRock
     */
    getPackageList() {
      const { order_id } = this
      fetchPackageList({
        order_id
      }).then((res) => {
        const { data } = res
        this.packages = data
      })
    },
    /**
     * 获取会员用户购买信息
     */
    async getMemberInfo() {
      const {
        code,
        data: { list }
      } = await getMemberbuyInfo()
      if (code == 0) {
        let vm = this
        this.memberInfo = list
        vm.swiperOption.initialSlide = vm.$store.state.user.swiperIndex // 再次进入页面设置轮播从上一次的位置开始播放
        let swiperTimes = vm.memberInfo.length
        if (swiperTimes) {
          vm.timerInfo = setTimeout(() => {
            vm.getMemberInfo()
          }, swiperTimes * 3000)
        } else {
          if (vm.timerInfo) {
            clearTimeout(vm.timerInfo)
            vm.timerInfo = null
          }
        }
        vm.$once('hook:beforeDestroy', () => {
          clearTimeout(vm.timerInfo)
          vm.timerInfo = null
        })
      }
    },
    setStringlen(name) {
      let str = ''
      str = name.length > 1 ? name.substr(0, 1) + '***' + name.substr(name.length - 1, 1) : name + '***'
      return str
    },
    // 处理 个人连续包月或企业年付 只支付一次
    handleMonthPay(info){
      if(info.is_contract == 1) return this.$message.warning('您已购买该商品，不可重复购买。')
      if(info.is_month == 1){
        this.paymentMonth(info)
      }
      if(info.is_sum == 3){
        this.immediatePayment(info, true)
      }
    },
    // 订阅开续包月
    paymentMonth(info){
      this.payGoodInfo = info
      this.initMonthOrderSign(info)
      this.$refs.aliMonthPayRef.handleOpen(info.price)
    },
    initMonthOrderSign(info){
      initMonthOrderSign({goods_id: info.goods_id}).then((res)=>{
        const { alipay_code, contract_no} = res.data
        this.aliMonthPayCode = alipay_code
        this.handleClearCountDown('aliPayOrderTimer')
        this.handleViewMonthOrderPayStatus(contract_no, 'aliPayOrderTimer')
      })
    },
    // 监听连续包月支付结果
    async handleViewMonthOrderPayStatus(contract_no, name) {
      try {
        const { data } = await fetchMonthOrderStatus({contract_no})
        // 判断订单的状态 -1表示订单状态不存在 0表示订单支付成功
        if (data.status == -1) {
          // 添加定时器
          this[name] = setTimeout(() => {
            this.handleViewMonthOrderPayStatus(contract_no, name)
          }, payReloadTime)
          // this.$once('hook:beforeDestroy', () => {
          //   clearTimeout(this[name])
          //   this[name] = null
          // })
          return
        } else if (data.status == 0) {
          this.$refs.aliMonthPayRef.handleBeforeClose()
          this.handleNavMember()
          this.sendEventapi()
          this.$store.dispatch('user/getUserInfo')
          this.getPackageList()
        }
        this.handleClearCountDown(name)
      } catch (e) {
        this.handleClearCountDown(name)
      }
    },
    // 立即支付
    immediatePayment( info, order_id) {
      this.payGoodInfo = info
      const { goods_id, has_rel_goods } = info
      //有进阶套餐的
      if (has_rel_goods == 1) {
        this.$refs.ladderPayRef.handleOpen(info, order_id)
      } else {
        //升级会员的
        if (order_id) {
          this.getPayQrCodeUpgrade(goods_id)
        } else {
          this.getPayQrCode(goods_id)
        }
        this.dialogVisiblemember = true
      }
    },
    //未升级的订单
    getPayQrCode(item) {
      //个人会员
      this.openMemberID = item
      const goods_id = item
      const params = {
        goods_id: goods_id,
        share_token: window.localStorage.getItem('share_token')
          ? window.localStorage.getItem('share_token')
          : ''
      }
      Promise.all([
        fetchOrderPayByWxPay(params),
        fetchOrderPayByAliPay(params)
      ]).then((res) => {
        this.wxPayOrderNo = res[0].data.order_no
        this.aliPayOrderNo = res[1].data.order_no
        this.wxPayQrCode = res[0].data.pay_url
        this.aliPayQrCode = res[1].data.pay_url
        //this.handleChangePayInfo()
        this.handleViewOrderPayStatus(this.wxPayOrderNo, 'wxPayTimer')
        this.handleViewOrderPayStatus(this.aliPayOrderNo, 'aliPayOrderTimer')
      })
    },
    //升级后的的订单
    getPayQrCodeUpgrade(item) {
      //个人会员
      this.openMemberID = item
      const goods_id = item
      const order_id = this.order_id
      const params = {
        goods_id: goods_id,
        order_id: order_id || '',
        share_token: window.localStorage.getItem('share_token')
          ? window.localStorage.getItem('share_token')
          : ''
      }
      Promise.all([
        fetchOrderPayByWxPay(params),
        fetchOrderPayByAliPay(params)
      ]).then((res) => {
        this.wxPayOrderNo = res[0].data.order_no
        this.aliPayOrderNo = res[1].data.order_no
        this.wxPayQrCode = res[0].data.pay_url
        this.aliPayQrCode = res[1].data.pay_url
        //this.handleChangePayInfo()
        this.handleViewOrderPayStatus(this.wxPayOrderNo, 'wxPayTimer')
        this.handleViewOrderPayStatus(this.aliPayOrderNo, 'aliPayOrderTimer')
      })
    },
    /**
     * 处理查看订单的支付状态
     * 2021-12-23 10:51:52
     * @author SnowRock
     */
    async handleViewOrderPayStatus(order_no, name) {
      // 进入即刻清除定时器
      try {
        const { data } = await fetchOrderPayStatusByPackage({
          order_no,
          share_token: window.localStorage.getItem('share_token')
            ? window.localStorage.getItem('share_token')
            : ''
        })
        // 判断订单的状态 -1表示订单状态不存在 0表示订单支付成功
        if (data.status == -1) {
          // 添加定时器
          this[name] = setTimeout(() => {
            this.handleViewOrderPayStatus(order_no, name)
          }, payReloadTime)
          // this.$once('hook:beforeDestroy', () => {
          //   clearTimeout(this[name])
          //   this[name] = null
          // })
          return
        } else if (data.status == 0) {
          this.dialogVisiblemember = false
          this.handleClearCountDown('wxPayTimer')
          this.handleClearCountDown('aliPayOrderTimer')
          this.handleNavMember()
          this.sendEventapi()
          this.$store.dispatch('user/getUserInfo')
        }
        this.handleClearCountDown(name)
      } catch (e) {
        console.log(e)
        this.handleClearCountDown(name)
      }
    },
    /**
     * 处理提交信息
     * 2021-12-23 10:14:29
     * @author SnowRock
     */
    handleSubmitPublicInfo() {
      this.$refs.publicForm.validate(async (valid) => {
        if (!valid) return
        const { receipt: receipt_path, remark } = this.publicForm
        const params = {
          receipt_path,
          remark,
          order_no: this.order_no
        }
        try {
          await submitPublicPayReceipt(params)
          this.$message.success('提交成功')
          this.sendEventapi()
          this.$refs.publicForm.resetFields() // 清空付款回执单跟付款备注
          this.isclear = true
          this.handleNavMember()
          this.dialogVisiblemember = false
          this.payType = 1
        } catch (e) {
          console.log(e)
        }
      })
    },
    /**
     * 事件埋点
     */
    sendEventapi() {
      const obj = {
        package_name: this.payGoodInfo.name,
        package_type: this.payGoodInfo.type == 1 ? '个人' : '企业',
        pay_type: this.payType == 1 ? '在线支付（支付宝/微信）' : '对公转账',
        op_date: formDateTime(),
        m_name: '',
        mid: '',
        reg_date: this.payGoodInfo.create_at,
        user_name: this.userInfo.name,
        user_id: this.userInfo.user_id
      }
      send_eventApi('e_pay', obj)
    },
    /**
     * 处理选择套餐
     * @param info
     */
    // handleSelectPackage(info) {
    //   if (this.selectPackage && this.selectPackage.goods_id === info.goods_id)
    //     return
    //   this.selectPackage = info
    //   this.packageType = info.type
    //   this.package_name = info.name
    // },
    handleBeforeClose() {
      this.payType = 1
      this.dialogVisiblemember = false
      this.handleClearCountDown('aliPayOrderTimer')
      this.handleClearCountDown('wxPayTimer')
    },
    /**
     * 处理支付方式发生改变时回调信息
     * 2021-12-22 20:58:45
     * @author SnowRock
     */
    handleChangePayInfo(type) {
      this.payType = type
      if (type == 2) {
        this.handleClearCountDown('wxPayTimer')
        this.handleClearCountDown('aliPayOrderTimer')
        this.getPublicInfo()
      }
    },
    /**
     * 处理对公转账信息
     * 2021-12-23 10:16:13
     * @author SnowRock
     */
    getPublicInfo() {
      const params = {
        goods_id: this.openMemberID,
        order_id: this.order_id || '',
        share_token: window.localStorage.getItem('share_token')
          ? window.localStorage.getItem('share_token')
          : ''
      }
      fetchOrderPayByPublicPay(params).then((res) => {
        const {
          data: { order_no }
        } = res
        this.order_no = order_no
      })
    },
    topay(e) {
      const {type, relGood, orderId } = e
      this.payGoodInfo = relGood
      if (type == 1) {
        //原套餐
        this.ladderTopay = false
        this.$refs.ladderPayRef.handleClosecreator()
        this.dialogVisiblemember = true
        if (orderId) {
          this.getPayQrCodeUpgrade(relGood.goods_id)
        } else {
          this.getPayQrCode(relGood.goods_id)
        }
      } else {
        //进阶套餐
        this.$refs.ladderPayRef.handleClosecreator()
        this.dialogVisiblemember = true
        if (orderId) {
          this.getPayQrCodeUpgrade(relGood.goods_id)
        } else {
          this.getPayQrCode(relGood.goods_id)
        }
      }
    },
     /**
     * 清除定时器
     */
    handleClearCountDown(name) {
      const timer = this[name]
      if (timer) {
        clearTimeout(timer)
        this[name] = null
      }
    },
    handleNavMember() {
      this.showLetter = true
    },
    /**
     * 关闭致用户的一封信
     */
    closeLetter() {
      this.showLetter = false
    },
    handleMontyPayClose() {
      this.handleClearCountDown('aliPayOrderTimer')
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  .swiper-container {
    height: 20px;
    display: flex;
    align-items: center;

    .swiper-slide {
      p {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        color: #333333;
        display: flex;
        align-items: center;
        cursor: default;

        span {
          max-width: 260px;
          display: inline-block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}
.PurchaseTips {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 19px;
  padding: 7px 30px;
  background: rgba(255, 255, 255, .5);
  position: absolute;
  bottom: 84px;
  right: 0;
  display: flex;

  .PurchaseTipsICON {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #fc4243;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 6px;
  }
}
.package-container{
  min-height: 100vh;
  min-width: 1000px;
  .banner-wrap {
    width: 100%;
    height: calc(260px + 60px);
    position: relative;
    .crumb-wapper {
      width: 1200px;
      position: absolute;
      top: 84px;
      left: 50%;
      transform: translateX(-50%);
    }
    .banner-container {
      width: 1200px;
      height: calc(260px + 60px);
      margin: 0 auto;
      padding-top: 60px;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      div.title-wrap{
        width: 100%;
        position: absolute;
        top: 100px;
        color: #FFFFFF;
        text-align: center;
        h1{
          margin: 0;
          font-size: 40px;
          font-weight: bold;
          letter-spacing: 6px;
        }
        h2{
          margin-top: 12px;
          font-size: 16px;
          letter-spacing: 12px;
        }
      }
    }
    img {
      width: 100%;
      height: 100%;
    }

  }
  .goods-container{
    .tab-wrap{
      position: relative;
      top: -50px;
    }
    .goods-tab{
      width: 960px;
      height: 50px;
      margin: 0 auto;
      background: linear-gradient(168deg, #FE7273 0%, #FC4243 100%);
      border-radius: 24px 24px 0px 0px;
      font-size: 20px;
      font-weight: bold;
      display: flex;
      color: #FFFFFF;
      & > div{
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 50%;
      }
      .person-tab{
        padding: 0 57px;
        span {
          font-size: 14px;
          color: #fff;
          margin-left: 12px;
          font-weight: normal;
          font-style: normal;
        }
      }
      .company-tab {
        padding: 0 36px;
        strong {
          display: flex;
          align-items: center;
        }
        p{
          margin: 0;
          margin-left: 10px;
          border-radius: 2px;
          width: 68px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          font-size: 12px;
          font-style: normal;
          color: #CE8414;
          background: linear-gradient(135deg, #FFE99D 0%, #FFD27C 100%);
          transform: skew(-8deg);
        }
        span{
          font-size: 14px;
          color: #fff;
          margin-left: 12px;
          font-weight: normal;
          font-style: normal;
        }
      }
      .person-active, .company-active {
        width: 542px;
        height: 74px;
        // line-height: 74px;
        padding: 0 67px 0 57px;
        position: relative;
        top: -24px;
        padding-top: 14px;
      }
      .person-active{
        color: #333333;
        left: -8px;
        background: url('~@/assets/image/limitExempt/person_select_bj.png') no-repeat;
        background-size: 100% 100%;
        span{
          color: #999;
        }
      }
      .company-active {
        color: #333333;
        right: -8px;
        background: url('~@/assets/image/limitExempt/qy_select_bj.png') no-repeat;
        background-size: 100% 100%;
        padding-left: 90px;
        span{
          color: #999;
          font-size: 14px;
        }
      }
    }
    .tabCompany{
      background: #333333;
      color: #F8E3AD;

    }
    .goods-wrap {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      .goods-item{
        margin-right: 24px;
      }
      .goods-item:last-child{
        margin-right: 0;
      }
      .person-goods-item, .company-goods-item{
        cursor: pointer;
        position: relative;
        border-radius: 20px;
        position: relative;
        .title {
          width: 100%;
          height: 60px;
          text-align: center;
          color: #333333;
          font-size: 20px;
          line-height: 70px;
          font-weight: bold;
        }
        .content{
          margin: 0 auto;
          width: 216px;
        }
        .origin-price {
          font-size: 12px;
          color: rgba(153,153,153,0.5);
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: -17px;
          text-decoration: line-through;
        }
        .price {
          margin: 2px 0 10px 0;
          text-align: center;
          font-family: alipuhuir;
          font-size: 50px;
          i{
            font-style: normal;
            font-weight: bolder;
            font-size: 30px;
          }
        }
        .desc{
          color: #999999;
          font-size: 14px;
          margin-bottom: 20px;
          position: relative;
          div{
            @include center-middle-flex($justify: space-between);
            margin-bottom: 8px;
          }
        }
        .pay {
          width: 220px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          border-radius: 20px;
          border: 1px solid #999999;
          color: #666666;
          font-size: 16px;
        }
        .double-t {
          width: 100px;
          height: 28px;
          line-height: 28px;
          text-align: center;
          background: url('~@/assets/image/activity_icon_12.png') no-repeat;
          background-size: 100% 100%;
          position: absolute;
          right: -2px;
          top: -3px;
          font-size: 14px;
          color: #FFFFFF;
        }
        .special-mask{
          width: 80px;
          height: 80px;
          background: url('~@/assets/image/set_month_mask.png') no-repeat;
          background-size: 100% 100%;
          position: absolute;
          right: -2px;
          top: -3px;
          @include center-middle-flex($justify: center);
          flex-direction: column;
          border-radius: 0 20px 0;
          span{
            position: absolute;
            color: rgba(229, 94, 13, .7);
          }
          span.lisheng{
            font-family: ximai;
            font-size: 14px;
            top: 16px;
            left: 43px;
            transform:  rotate(34deg);
          }
          span.money{
            font-family: ahouti;
            font-size: 18px;
            top: 33px;
            left: 50%;
            transform: translate(-50%) rotate(34deg);
          }
        }
      }
      .person-goods-item{
        border: 1px solid #E2E2E2;
        @include size(282px, 284px);
        .price {
          color: #FF2F0F;
          background: linear-gradient(135deg, #FC4243 0%, #FE7273 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          // display: block;
        }
        .desc{
          .num {
            font-weight: 600;
            background: linear-gradient(135deg, #FE7273 0%, #FC4243 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
      .person-goods-item.month-good, .person-goods-item.choose {
        background: linear-gradient(135deg, #FFFFFF 0%, #FFF8F9 100%);
        .pay {
          border: none;
          background: linear-gradient(135deg, #FE7273 0%, #FC4243 100%);
          color: #FFFFFF;
        }
      }
      .person-goods-item.month-good {
        box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.12);
        border: 2px solid #FFDADA;
        .title{
          color: #FFFFFF;
          border-radius: 18px 18px 0px 0px;
          background: linear-gradient(135deg, rgba(252,70,71,0.7) 0%, rgba(252,70,71,0.7) 12%, rgba(255,55,56,0.2) 100%);
          box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.12), inset 1px 1px 5px 0px #FFFFFF, inset 1px 1px 2px 0px rgba(254,147,148,0.5);
        }
        .pay{
          font-weight: bold;
        }
      }
      .person-goods-item.choose {
        border-radius: 20px;
        border: 3px solid rgba(254, 113, 114, .5);
        .pay{
          font-weight: bold;
        }
      }
      .person-goods-item.month-choose{
        .pay {
          border: none;
          background: linear-gradient(135deg, #FC4243 0%, #FE7273 100%);
        }

      }

      .company-goods-item {
        border: 1px solid #E2E2E2;
        @include size(282px, 308px);
        .price {
          color: #FF2F0F;
          background: linear-gradient(135deg, #FC8742 0%, #FEC372 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .desc{
          .num {
            font-weight: 600;
            background: linear-gradient(90deg, #FC8742 0%, #FEC372 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
      .company-goods-item.month-good, .company-goods-item.choose {
        background: linear-gradient(135deg, #FFFFFF 0%, #FFFCF3 100%);
        .pay {
          border: none;
          background: linear-gradient(135deg, #FEC372 0%, #FC8742 100%);
          color: #FFFFFF;
        }
      }
      .company-goods-item.month-good {
        border: 2px solid #FEEBC7;
        .title{
          color: #AD6811;
          border-radius: 18px 18px 0px 0px;
          background: linear-gradient(135deg, #FEF2D1 0%, #FDD37B 12%, #FEF2D1 100%);
          box-shadow: 1px 1px 2px 0px #FDD88A;
        }
      }
      .company-goods-item.choose {
        border-radius: 20px;
        border: 3px solid #FEEBC7;
      }
      .company-goods-item.month-choose{
        .pay {
          border: none;
          background: linear-gradient(135deg, #FC8742 0%, #FEC372 100%);
        }

      }
    }
  }
  .fix-content{
    margin-top: 30px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    color: #0061F3;
    display: flex;
    justify-content: center;
    .icons{
      margin-left: 7px;
      position: relative;
      font-size: 18px;
      i:first-child{
        position: absolute;
        top: .5px;
        left: 0;
      }
      i:last-child{
        position: absolute;
        top: 5.5px;
        left: 0;
      }
    }
  }
}
.payDiage{
  /deep/ {
    .el-dialog {
      position: relative;
      border-radius: 5px;
      .el-dialog__header{
        display: none;
      }
    }
    .el-dialog__body{
      padding: 40px 64px 36px 38px;
    }
  }
  .pay-continer{
    display: flex;
    .pay-type{
      margin-top: 32px;
      div{
        cursor: pointer;
        width: 116px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        margin-bottom: 20px;
        img{
          margin-right: 10px;
        }
      }
      .payActive{
        border: 1px solid #E52A0D;
      }
    }
    .pay-splice-line{
      width: 1px;
      height: 270px;
      border-right: 1px solid #DCDFE6;
      margin: 24px 19px 0 30px;
    }

  }
}
.public-container, .qr-code-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .pay-amount{
    color: #333333;
    span{
      font-size: 30px;
      color: #E52A0D;
      font-weight: bold;
      i{
        font-style: normal;
        font-size: 14px;
      }
    }
  }
  .special-pay-tip{
    margin-top: 12px;
    font-size: 14px;
    color: #999999;
  }
}
.qr-code-container {
  .agree{
    text-align: center;
    font-size: 12px;
    color: #999;
    line-height: 16px;
  }
  .qr-tip{
    margin-top: 30px;
    color: #999999;
    font-size: 12px;
    span{
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .qr-code-wrap {
    display: flex;
  }
  .qr-code {
    padding: 20px;
    text-align: center;
    .scande_border {
      width: 150px;
      height: 150px;
      border: 1px solid rgba(195, 195, 205, .6);
      border-radius: 2px;

      vue-qrcode {
        margin: auto;
        line-height: 130px;
      }
    }
    .code {
      width: 150px;
      height: 150px;
      background: #d8d8d8;
      border: 1px solid #979797;
      margin-bottom: 26px;
    }

    p {
      display: flex;
      justify-content: center;

      img {
        height: 12px;
        width: 12px;
      }
    }
  }
}

.public-container {
  width: 380px;
  /deep/ {
    .el-descriptions{
      font-size: 12px;
      padding: 20px 0 0 20px;
      .el-descriptions-item__label{
        color: #999;
        width: 78px;
      }
      .el-descriptions-item__content {
        color: #333333;
        font-weight: bold;
      }
      .el-descriptions-item__cell{
        padding-bottom: 6px;
      }
    }

    .el-form{
      margin-left: 20px;
      width: 100%;
      .el-form-item{
        margin-bottom: 10px;
        .el-form-item__label{
          font-size: 12px;
          text-align: left;
          color: #999;
          width: 98px;
        }
        .el-form-item__content{
          line-height: 0;
          .el-textarea {
            width: 248px;
            font-size: 12px;
            .el-textarea__inner{
              width: 248px;
              height: 54px;
              background: #F2F3F5;
              border: none;
            }
          }
        }
        .el-form-item__error{
          left: 98px;
        }

      }

    }
  }

  .tip {
    // margin-top: 10px;
    font-size: 12px;
    color: #999999;
    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
.submit-btn-container {
  width: 400px;
  text-align: center;
  margin-top: 30px;

  .el-button {
    width: 96px;
    height: 32px;
    line-height: 32px;
    padding: 0;
  }
}

.customVip{
  position: fixed;
  bottom: 0;
  right: 40px;
  width: 100%;
  z-index: 10000;
  cursor: pointer;
  width: 460px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: bold;
  background-color: #E52A0D;
}
</style>
