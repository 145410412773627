<template>
  <div class="license-container">
    <div class="license-title">
      <h1>授权许可对比</h1>
      <h4>MEMBERSHIP AUTHORIZATION CONTRAST</h4>
    </div>
    <div class="table-header">
      <div>
        <span class="title">会员授权许可对比</span>
        <span class="desc"></span>
        <span class="person">个人会员（非商用）<el-tooltip content="被授权人仅限为自然人，不得将授权作品用于除被授权人以外的任何第三方机构或公司；被授权项目仅适用于非商业行为。" placement="top"><i class="el-icon-question"></i></el-tooltip></span>
        <span class="company">企业会员</span>
      </div>
    </div>
    <div class="table-content">
      <div>
        <div>
          <span class="title">授权期限：</span>
          <span class="desc">* 电商店铺用途、直播、公播授权期限与订阅服务期限一致</span>
          <span class="person">随片永久</span>
          <span class="company">随片永久</span>
        </div>
      </div>
      <div>
        <div>
          <span class="title">投放地域：</span>
          <span class="desc"></span>
          <span class="person">中国</span>
          <span class="company">全球</span>
        </div>
      </div>
      <div>
        <div>
          <span class="title">商业广告/宣传片：</span>
          <span class="desc">发布于各类互联网上、移动端、电视、电商平台和线下的广告等（图片素材不能用于电视及线下广告）</span>
          <span class="person"><img src="~@/assets/svg/license_not_allow.svg" alt=""></span>
          <span class="company"><img src="~@/assets/svg/license_allow.svg" alt=""></span>
        </div>
      </div>
      <div>
        <div>
          <span class="title">自媒体平台：</span>
          <span class="desc">国内自媒体平台：微信公众号、视频号、微博、哔哩哔哩、斗鱼、抖音、快手等</span>
          <span class="person"><img src="~@/assets/svg/license_allow.svg" alt=""></span>
          <span class="company"><img src="~@/assets/svg/license_allow.svg" alt=""></span>
        </div>
      </div>
      <div>
        <div>
          <span class="title"></span>
          <span class="desc">海外自媒体平台：YouTube、Facebook、TikTok、Twitter、Instagram等</span>
          <span class="person"><img src="~@/assets/svg/license_not_allow.svg" alt=""></span>
          <span class="company"><img src="~@/assets/svg/license_allow.svg" alt=""></span>
        </div>
      </div>
      <div>
        <div>
          <span class="title">直播/电台FM：</span>
          <span class="desc">广播剧、有声书、电台FM、网络节目、网络直播节目等</span>
          <span class="person"><img src="~@/assets/svg/license_allow.svg" alt=""></span>
          <span class="company"><img src="~@/assets/svg/license_allow.svg" alt=""></span>
        </div>
      </div>
      <div>
        <div>
          <span class="title">产品内置：</span>
          <span class="desc">个人制作小程序、H5、小游戏等（图片素材仅能用于微信小程序配图）</span>
          <span class="person"><img src="~@/assets/svg/license_allow.svg" alt=""></span>
          <span class="company"><img src="~@/assets/svg/license_allow.svg" alt=""></span>
        </div>
      </div>
      <div>
        <div>
          <span class="title"></span>
          <span class="desc">企业制作小程序、H5、小游戏；官网、网站装饰、APP、皮肤/手机主题等</span>
          <span class="person"><img src="~@/assets/svg/license_not_allow.svg" alt=""></span>
          <span class="company"><img src="~@/assets/svg/license_allow.svg" alt=""></span>
        </div>
      </div>
      <div>
        <div>
          <span class="title">生活用途：</span>
          <span class="desc">家庭视频：电子相册、婚礼视频、生活视频等纪念性音视频内容</span>
          <span class="person"><img src="~@/assets/svg/license_allow.svg" alt=""></span>
          <span class="company"><img src="~@/assets/svg/license_allow.svg" alt=""></span>
        </div>
      </div>
      <div>
        <div>
          <span class="title"></span>
          <span class="desc">个人作品：个人航拍、旅拍、短剧等</span>
          <span class="person"><img src="~@/assets/svg/license_allow.svg" alt=""></span>
          <span class="company"><img src="~@/assets/svg/license_allow.svg" alt=""></span>
        </div>
      </div>
      <div>
        <div>
          <span class="title">办公用途：</span>
          <span class="desc">企业、事业、政府机关、团体、学校、医院等单位内部宣传或内部活动使用；VI设计、营销策划方案、商业提案、项目计划书、年度报告等配图（仅图片素材可以使用）</span>
          <span class="person"><img src="~@/assets/svg/license_not_allow.svg" alt=""></span>
          <span class="company"><img src="~@/assets/svg/license_allow.svg" alt=""></span>
        </div>
      </div>
      <div>
        <div>
          <span class="title">课程教育用途：<br>(图片素材不可使用)</span>
          <span class="desc">个人开设的网络直播课、毕业设计、个人参赛等课程</span>
          <span class="person"><img src="~@/assets/svg/license_allow.svg" alt=""></span>
          <span class="company"><img src="~@/assets/svg/license_allow.svg" alt=""></span>
        </div>
      </div>
      <div>
        <div>
          <span class="title"></span>
          <span class="desc">培训机构、公开课程、网络直播课程、在线录播课程、教育平台等付费课程</span>
          <span class="person"><img src="~@/assets/svg/license_not_allow.svg" alt=""></span>
          <span class="company"><img src="~@/assets/svg/license_allow.svg" alt=""></span>
        </div>
      </div>
      <div>
        <div>
          <span class="title">电商店铺用途：</span>
          <span class="desc">淘宝、京东、天猫、拼多多等第三方电商平台背景视频音乐、装饰配图</span>
          <span class="person"><img src="~@/assets/svg/license_not_allow.svg" alt=""></span>
          <span class="company"><img src="~@/assets/svg/license_allow.svg" alt=""></span>
        </div>
      </div>
      <div>
        <div>
          <span class="title">线下场所公播：</span>
          <span class="desc">商超、餐饮、咖啡厅、影院、酒吧、年会、展会、发布会等线下场所作为背景播放（图片素材不可使用）</span>
          <span class="person"><img src="~@/assets/svg/license_not_allow.svg" alt=""></span>
          <span class="company"><img src="~@/assets/svg/license_allow.svg" alt=""></span>
        </div>
      </div>
      <div>
        <div>
          <span class="title">特殊授权:</span>
          <span class="desc">制作成电影、电视剧、综艺以及NFT进行使用</span>
          <span class="person"><img src="~@/assets/svg/license_not_allow.svg" alt=""></span>
          <span class="company"><img src="~@/assets/svg/license_not_allow.svg" alt=""></span>
        </div>
      </div>
      <div>
        <div>
          <span class="title"></span>
          <span class="desc">进行转授权、原创认证；用于商标、logo或实体印刷</span>
          <span class="person"><img src="~@/assets/svg/license_not_allow.svg" alt=""></span>
          <span class="company"><img src="~@/assets/svg/license_not_allow.svg" alt=""></span>
        </div>
      </div>
      <div>
        <div>
          <span class="title"></span>
          <span class="desc">肖像权敏感授权：医疗、药物、保健品、养生、美容、整形、交友等行业</span>
          <span class="person"><img src="~@/assets/svg/license_not_allow.svg" alt=""></span>
          <span class="company"><img src="~@/assets/svg/license_not_allow.svg" alt=""></span>
        </div>
      </div>
      <div>
        <div>
          <span class="title">其他项目：</span>
          <span class="special-content">
            <span class="c">如果您正在制作以上未列出的项目或有增加被授权企业数的需求，我们的团队将很乐意为您的需求进行量身定制</span>
            <span class="contract-wrap">
              <span class="contract" @click="qimoChatClick"></span>
              <span class="contract-border"></span>
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="table-footer">
      注：订阅会员可下载全站素材，并在相应会员授权范围内使用，个人会员仅支持授权非商用项目，若您的项目属于商业用途请订阅企业会员，会员产品购买成功后不支持退换和转让，其中图片素材不可用于原创认证及线下渠道。
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    qimoChatClick() {
      window.qimoChatClick();
    }
  }
};
</script>
<style lang="scss" scoped>
.license-container {
  max-width: 1200px;
  margin: 0 auto;
  .license-title{
    @include center-middle-flex($justify: center);
    flex-direction: column;
    margin: 60px 0 36px;
    h1, h4 {
      margin: 0;
    }
    h1{
      font-size: 32px;
      color: #333333;
      font-weight: bold;
    }
    h4{
      color: rgba(195, 195, 205, .6);
      font-size: 16px;
      letter-spacing: 1px;
    }
  }
  .table-header {
    font-size: 16px;
    color: #333;
    font-weight: bold;
    border-radius: 16px;
    background-color: #F5F5F5;
    cursor: pointer;
    margin-bottom: 12px;
    padding: 0 25px 0 50px;
    div{
      width: 100%;
      height: 60px;
      line-height: 60px;
    }
    .title {
      width: 15%;
    }
    .desc {
      width: 49%;
    }
    .person {
      width: 18%;
      text-align: center;
    }
    .company {
      text-align: center;
      width: 18%;
    }
  }
  .table-content {
    font-size: 14px;
    border: 1px solid #E2E2E2;
    border-radius: 16px;
    cursor: pointer;
    & > div{
      width: 100%;
      padding: 0 25px 0 50px;
      & > div{
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #E2E2E2;
      }
      &:last-child {
        div{
          border-bottom: none;
        }
      }
    }
    div:hover{
      background-color: #F5F5F5;
    }
    .title {
      width: 15%;
      color: #333333;
      font-weight: bold;

    }
    .desc {
      width: 49%;
      color: #666666;

    }
    .person {
      width: 18%;
      text-align: center;
    }
    .company {
      text-align: center;
      width: 18%;
    }
    .special-content {
      width: 85%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .c{
        width: 78%;
        color: #666666;
      }
      .contract-wrap {
        position: relative;
        width: 160px;
        height: 40px;
        border-radius: 25px;
        background: linear-gradient(135deg, #DEC7C0 0%, #AB6C40 100%);

      }
      // .contract-border{
      //   position: absolute;
      // }
      .contract{
        width: 158px;
        height: 38px;
        line-height: 38px;
        position: absolute;
        top: 1px;
        left: 1px;
        background: linear-gradient(135deg, #4C4845 0%, #1B1719 100%);
        border-radius: 25px;
        font-size: 16px;
        font-weight: 500;
        margin-right: 15px;
        &::after{
          content: '联系客服定制';
          width: 160px;
          text-align: center;
          position: absolute;
          background: linear-gradient(90deg, #FAE0DE 0%, #D8AC96 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
  .table-footer{
    width: 100%;
    padding: 20px 20px 50px;
    color: #999999;
    font-size: 14px;
    line-height: 22px;
  }
}
</style>
