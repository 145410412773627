<template>
    <el-dialog class="dialog-for-custom" :visible="customVisiable" :destroy-on-close="false" :show-close="true"
      :append-to-body="false" :before-close="handleBeforeClose" width="400px" top="35vh">
      <div class="custom-container">
        <h1>扫码添加微信</h1>
        <h2>按需定制</h2>
        <img src="@/assets/image/custom_vip.jpg" alt="">
      </div>
    </el-dialog>
</template>

<script>
export default {
  name: 'Custom',
  data() {
    return {
      customVisiable: false
    }
  },
  methods: {
    handleOpen(){
      this.customVisiable = true
    },
    handleBeforeClose() {
      this.customVisiable = false
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-for-custom{
  /deep/ {
    .el-dialog {
      position: relative;
      border-radius: 5px;
      .el-dialog__header{
        display: none;
      }
    }
    .el-dialog__body{
      padding: 24px 40px 24px;
    }
  }
}
.custom-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 20px;
    color: #333;
  }
  h2{
    font-size: 14px;
    font-weight: bold;
    color: #E52A0D;
  }
  img{
    width: 200px;
    height: 200px;
  }
}
</style>
