<template>
  <div class="problem-container">
    <div class="problem-title">
      <h1>常见问题</h1>
      <h4>Q&A</h4>
    </div>
    <div class="problem-content">
      <div class="problem-item">
        <span>个人会员跟企业会员有什么区别 ？</span>
        <p>个人会员下载的素材只可用作私人项目使用不可商用，企业会员下载的素材可用作企业项目可商用，详情请查看 <i @click="handleScroll">会员许可对比</i></p>
      </div>
      <div class="problem-item">
        <span>素材可以商用吗 ？</span>
        <p>PlayList全站资源（音乐、音效、视频、图片、AI语音）拥有正规版权，均可以商用。个人会员用户仅支持个人项目使用非商用授权，企业会员支持商用，下载素材后按项目需求激活许可授权书即可。</p>
      </div>
      <div class="problem-item">
        <span>有合同吗? 怎么申请 ？</span>
        <p>① 付费后，鼠标悬浮在网站右上角头像上，点击【我的会员】；<br>
          ② 在【我的会员】页面，对应会员列表后方选择【查看详情】；<br>
          ③ 会员详情页面，点击【订阅服务协议】，输入您的合同信息，即可下载订阅服务协议(合同)
        </p>
      </div>
      <div class="problem-item">
        <span>怎么申请发票 ？</span>
        <p>个人会员支持开具普票，企业会员支持开具普票/专票；网站右上角鼠标悬浮到头像处，点击【发票管理】，在列表处选择您需要开具的发票，输入开票信息即可。电子普通发票不限金额，增值税专用发票需金额大于1000元可开具。</p>
      </div>
    </div>
    <div class="problem-footer" @click="handleNavHelp">
      查看更多常见问题 <el-icon name="arrow-right" />
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    handleScroll(){
      let targetBox = this.$parent.$refs.licenseForm
      let target = targetBox.offsetTop - 60
      window.scroll({ top: target, left: 0, behavior: 'smooth' })
    },
    handleNavHelp() {
      this.$router.push({ path: '/help' })
    },
  }
};
</script>
<style lang="scss" scoped>
.problem-container {
  background-color: #FAFAFA;
  padding: 50px 0;
  color: #333333;
  font-size: 16px;
  .problem-title{
    @include center-middle-flex($justify: center);
    flex-direction: column;
    margin: 36px 0;
    h1, h4 {
      margin: 0;
    }
    h1{
      font-size: 32px;
      font-weight: bold;
    }
    h4{
      color: rgba(195, 195, 205, .6);
      letter-spacing: 1px;
    }
  }
  .problem-content{
    width: 1200px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
    .problem-item{
      width: 590px;
      background-color: #fff;
      height: 132px;
      margin-bottom: 20px;
      margin-right: 20px;
      border-radius: 20px;
      padding: 24px 40px 0;
      span{
        display: block;
        font-weight: bold;
        margin-bottom: 16px;
      }
      p{
        margin: 0;
        font-size: 12px;
        color: #666666;
      }
      i{
        cursor: pointer;
        color: #0061F3;
        font-style: normal;
        text-decoration: underline;
      }
    }
    .problem-item:nth-child(2n){
      margin-right: 0px;
    }
  }
  .problem-footer {
    cursor: pointer;
    text-align: center;
    margin-bottom: 30px;
  }
}
</style>
