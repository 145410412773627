import { render, staticRenderFns } from "./CommonProblem.vue?vue&type=template&id=76dcb4b8&scoped=true&"
import script from "./CommonProblem.vue?vue&type=script&lang=js&"
export * from "./CommonProblem.vue?vue&type=script&lang=js&"
import style0 from "./CommonProblem.vue?vue&type=style&index=0&id=76dcb4b8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76dcb4b8",
  null
  
)

export default component.exports